export const projects = [
    {
        name: 'Jaab',
        img: 'jaab.webp'
    },
    {
        name: 'Miraludo',
        img: 'miraludo.webp'
    },
    {
        name: 'ACL',
        img: 'acl.webp'
    },
    {
        name: 'Helioth',
        img: 'helioth.webp'
    },
    {
        name: 'Cities Destinations',
        img: 'cities.webp'
    },
    {
        name: 'Portfolio 2',
        img: 'portfolio2.webp'
    }
]